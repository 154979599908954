<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>ข้อมูล Sandbox</b-card-title>
      </div>
      <h4 class="m-0">
        <b-link @click="onClickEdit">
          ตั้งค่าการเชื่อมต่อ
        </b-link>
      </h4>
    </b-card-header>
    <hr class="m-0">
    <b-card-body>
      <b-form-group
        v-if="requestDetail.status === 2"
        label="การใช้งาน API"
        label-for="การใช้งานAPI"
        label-cols="6"
        label-class="text-secondary"
      >
        <b-form-checkbox
          v-model="isCredentialActivated"
          name="การใช้งานAPI"
          checked="true"
          class="text-right text-weight-bolder"
          switch
          @change="onChangeApiStatus"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="สถานะ"
        label-for="สถานะ"
        label-cols="6"
        label-class="text-secondary"
      >
        <b-form-input
          id="สถานะ"
          :value="requestStatus"
          class="text-right text-weight-bolder"
          :class="{
            'text-success': isApproved,
            'text-warning': !isApproved && !requestDetail.apiKeyStatus === 'suspended',
            'text-danger': requestDetail.apiKeyStatus === 'suspended'
          }"
          plaintext
        />
      </b-form-group>
      <b-form-group
        label="API Key"
        label-for="API"
        label-cols="6"
        label-class="text-secondary"
      >
        <b-form-input
          id="API"
          :value="requestDetail.apiKey || '-'"
          class="text-right text-weight-bolder"
          plaintext
        />
      </b-form-group>
      <b-form-group
        label="รูปแบบการเชื่อมต่อ"
        label-for="รูปแบบการเชื่อมต่อ"
        label-cols="6"
        label-class="text-secondary"
      >
        <b-form-input
          id="รูปแบบการเชื่อมต่อ"
          :value="requestDetail.integrate_type === 0?'Seamless': requestDetail.integrate_type === 1? 'Transfer' : requestDetail.integrate_type"
          class="text-right text-white"
          plaintext
        />
      </b-form-group>
      <b-form-group
        label="Callback URL"
        label-for="Callback URL"
        label-cols="6"
        label-class="text-secondary"
      >
        <b-form-input
          id="Callback URL"
          :value="requestDetail.callbackUrl ||'ยังไม่มีข้อมูล'"
          class="text-right text-white"
          plaintext
        />
      </b-form-group>
      <b-form-group
        label="Whitelist IP"
        label-for="Whitelist"
        label-cols="6"
        label-class="text-secondary"
      >
        <b-form-input
          v-for="ip in whitelist"
          :id="ip"
          :key="ip"
          :value="ip"
          class="text-right text-white"
          plaintext
        />
      </b-form-group>
    </b-card-body>

    <b-card-footer>
      <div
        v-if="isApproved"
        class="text-center"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
        >
          <feather-icon
            icon="LogInIcon"
            class="mr-50"
          />
          <span class="align-middle">เข้าใข้งานระบบหลังบ้านของตัวแทน</span>
        </b-button>
      </div>
      <b-row v-else-if="requestDetail.status === 0">
        <b-col class="text-center">
          <b-button
            v-b-modal.approve-request-modal
            variant="success"
            class="mr-1"
          >
            อนุมัติ
          </b-button>
          <b-button
            variant="danger"
            @click="onReject"
          >
            ไม่อนุมัติ
          </b-button>
        </b-col>
      </b-row>
    </b-card-footer>
    <approve-request-modal />
    <EditRequestModal
      id="edit-sandbox-modal"
      :request-detail="requestDetail"
      @submit="onSubmit"
    />
    <ConfirmModal
      id="activate-api-modal"
      title="เปิดการใช้งาน API"
      subtitle="ตัวแทนจะสามารถใช้งาน API ได้ตามปกติ"
      confirm-text="เปิดการใช้งาน"
      @confirm="onConfirmActivateCredential"
      @cancel="onCancelChangeApiStatus"
    />
    <ConfirmModal
      id="suspend-api-modal"
      title="ปิดการใช้งานชั่วคราว"
      subtitle="ตัวแทนจะไม่สามารถใช้งาน API ได้"
      confirm-text="ปิดการใช้งาน"
      confirm-variant="danger"
      @confirm="onConfirmSuspendCredential"
      @cancel="onCancelChangeApiStatus"
    />
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    ApproveRequestModal: () => import('./ApproveRequestModal.vue'),
    EditRequestModal: () => import('../EditRequestModal.vue'),
    ConfirmModal: () => import('../../commons/ConfirmModal.vue'),
  },
  data() {
    return {
      isCredentialActivated: false,
    }
  },
  computed: {
    ...mapState({
      isUpdateSuccess: (state) => state.request.isUpdateRequestSuccess,
      isActivateApiStatusSuccess: (state) =>
        state.agent.isActivateCredentialSuccess,
      isSuspendApiStatusSuccess: (state) =>
        state.agent.isSuspendCredentialSuccess,
    }),
    ...mapGetters(['requestDetail']),
    requestId() {
      const { query } = this.$route
      return query.id ? query.id : ''
    },
    isApproved() {
      const { apiKeyStatus } = this.requestDetail
      return apiKeyStatus === 'activated'
    },
    requestStatus() {
      const { apiKeyStatus } = this.requestDetail
      switch (apiKeyStatus) {
        case 'activated':
          return 'อนุมัติ'
        case 'suspended':
          return 'ไม่อนุมัติ'
        default:
          return 'รอการอนุมัติ'
      }
    },
    accountType() {
      const { accountType = 0 } = this.requestDetail
      if (accountType === 1) {
        return 'Owner'
      } else if (accountType === 2) {
        return 'Provider'
      } else if (accountType === 3) {
        return 'Operator'
      }
      return 'ไม่มีข้อมูล'
    },
    whitelist() {
      const { whitelistIp } = this.requestDetail
      return whitelistIp?.split(',') || ['']
    },
  },
  watch: {
    isUpdateSuccess(val) {
      if (val) {
        this.$emit('refetch')
      }
    },
    isActivateApiStatusSuccess(val) {
      if (val) {
        this.$emit('refetch')
      }
    },
    isSuspendApiStatusSuccess(val) {
      if (val) {
        this.$emit('refetch')
      }
    },
    requestDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions([
      'activateCredential',
      'suspendCredential',
      'rejectRequest',
      'updateRequest',
    ]),
    setData() {
      if (this.requestDetail.id) {
        this.isCredentialActivated = this.isApproved
      }
    },
    onConfirmActivateCredential() {
      this.activateCredential({
        integrationRequestID: this.requestDetail.id,
      })
    },
    onConfirmSuspendCredential() {
      this.suspendCredential({
        integrationRequestID: this.requestDetail.id,
      })
    },
    onCancelChangeApiStatus() {
      this.setData()
    },
    onChangeApiStatus() {
      if (this.isCredentialActivated) {
        this.$bvModal.show('activate-api-modal')
      } else {
        this.$bvModal.show('suspend-api-modal')
      }
    },
    onClickEdit() {
      this.$bvModal.show('edit-sandbox-modal')
    },
    onReject() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.rejectRequest(this.requestId)
          }
        })
    },
    async onSubmit(data) {
      await this.updateRequest({
        ...data,
        id: this.requestId,
      })
      this.$bvModal.hide('edit-sandbox-modal')
    },
  },
}
</script>
